import React, { Component } from 'react'
import { Helmet } from "react-helmet"
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { RichText } from 'prismic-reactjs';

import media from '../common/mediaQuery'
import { theme } from '../common/global'
import { Type } from '../common/type'

import Layout from '../components/layout'
import VideoPlayer from '../components/videoPlayer'
import Image from '../components/image'
import ImageBlock from '../components/ImageBlock'
import ContextConsumer from '../components/context'


class Project extends Component {

  render() {
    const { data } = this.props;
    // Required check for no data being returned
    const doc = data.prismic.allProjects.edges.slice(0,1).pop();
    if (!doc) return null;

    // Sort and display the different slice options
    const PostSlices = ({ slices }) => {
      return slices.map((slice, index) => {
        const res = (() => {
          switch(slice.type) {
            case 'image_block': return (
              <>
                { slice.primary.desktop_image &&
                  <ImageBlock copy={ slice.primary.copy } mobileImage={slice.primary.image_mobile.url} desktopImage={slice.primary.desktop_image.url} />
                }
              </>
            )
            default: return
          }
        })();
        return res;
      })
    }

    const ProjectPost = ({ project }) => {
      return (
        <PostSlices slices={ project } />
      );
    }
    
    return (
      <Layout>
        <Helmet>
          <title>Elkin Editions | { doc.node.title }</title>
          <meta name="description" content="Elkin Editions is an independent video production studio based in Lower Manhattan." />
          <meta property="og:image" content="../../share-image.jpg"></meta>
        </Helmet>
        <ContextConsumer>
          {({ contextData }) => (
            <PageWrapper isInverted={process.browser && localStorage.getItem('page_color')}>
              { doc.node.video_link ?
                <VideoPlayer videoStill={doc.node.hero_image.url} videoLink={doc.node.video_link} />
              : doc.node.hero_image.url ?
                <Image mobileImage={doc.node.hero_image_mobile.url} desktopImage={doc.node.hero_image.url} />
              : null
              }
              <Details>
                <Byline>
                  <Block>
                    <Type bold>{ doc.node.title }</Type>
                    <Type>{ doc.node.year }</Type>
                  </Block>
                  <Block>
                    <Type>{ doc.node.category }</Type>
                    {doc.node._meta.tags.map((tag) => (
                      <Type capitalized>+ { tag }</Type>
                    ))}
                  </Block>
                </Byline>
                <Descriptions>
                  <Block>
                    <Type>{ doc.node.project_copy }</Type>
                  </Block>
                  <Block>
                    <RichText render={doc.node.secondary_project_copy} />
                  </Block>
                </Descriptions>
              </Details>
              <Content>
                <ProjectPost project={ doc.node.body } />
              </Content>
            </PageWrapper>
          )}
        </ContextConsumer>
      </Layout>
    );
  }
}
 
export default Project;

const PageWrapper = styled.div`
  background-color: ${theme.colors.black};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  transition: all ${theme.speed.fast} ease-in-out;
  width: 100%;

  ${props => props.isInverted === 'true' && css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};

    a {
      color: ${theme.colors.black};
    }
  `}
`;

const Details = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 30px;
  padding: 0 25px;
  width: 100%;

  ${media.tablet`
    padding: 0 30px;
  `}
`;

const Byline = styled.div`
  width: 100%;

  ${media.tablet`
    flex: 0 0 50%;
    padding-right: 10px;
  `}

  ${media.tabletLarge`
    flex: 0 0 27.3%;
    padding-right: 20px;
  `}
`;

const Block = styled.div`
  margin-top: 15px;
  width: 100%;

  ${media.tablet`
    margin-top: 40px;
    width: 50%;
  `}

  ${media.tabletLarge`
    margin-top: 30px;
    width: 100%;
  `}

  &:first-of-type {
    margin-top: 0;
  }

  p {
    font-family: "Grotesk", sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    letter-spacing: -0.045rem;
    margin: 0;

    ${media.tablet`
      line-height: 1.5rem;
    `}

    +p {
      margin-top: 20px;
    }
  }
`;

const Descriptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;

  ${media.tablet`
    flex: 0 0 50%;
    margin-top: 0;
  `}

  ${media.tabletLarge`
    flex: 0 0 72.7%;
    padding-left: 7px;
  `}

  ${Block} {
    margin-top: 30px;
    width: 100%;

    ${media.tablet`
      margin-top: 30px;
      width: 100%;
    `}

    ${media.tabletLarge`
      margin-top: 0;
      width: calc(40%);

      &:last-of-type {
        margin-left: calc(10% + 4px);
        width: calc(40% - 10px);
      }
    `}

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

// const Hero = styled.div`
//   margin-top: 14px;
//   width: 100%;

//   ${media.tablet`
//     margin-top: 41px;
//   `}

//   picture {
//     width: 100%;
//   }
// `;

const Content = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const query = graphql`
query ProjectQuery($uid: String) {
  prismic {
    allProjects (uid: $uid) {
      edges {
        node {
          label
          title
          year
          category
          client
          project_copy
          secondary_project_copy
          video_link
          hero_image
          hero_image_mobile
          _meta {
            tags
          }
          body {
            ... on PRISMIC_ProjectBodyImage_block {
              type
              label
              primary {
                copy
                image_mobile
                desktop_image
              }
            }
            ... on PRISMIC_ProjectBodyImage_gallery {
              type
              fields {
                mobile_image
                desktop_image
              }
            }
          }
        }
      }
    }
  }
}`