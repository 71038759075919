import React, { Component } from 'react'
import styled from 'styled-components'

import media from '../common/mediaQuery'
import { Type } from '../common/type'

import Image from './image'

class ImageBlock extends Component {
  render() {
    const { copy, mobileImage, desktopImage } = this.props

    return (
      <BlockWrapper>
        <Content>
          <Type>{ copy }</Type>
        </Content>
        <MediaWrapper>
          <Image mobileImage={ mobileImage } desktopImage={ desktopImage } />
        </MediaWrapper>
      </BlockWrapper> 
    );
  }
}

export default ImageBlock

const BlockWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  padding: 0 25px;
  width: 100%;

  ${media.tablet`
    padding: 0 30px;
    margin-top: 35px;
  `}

  &:first-of-type {
    margin-top: 60px;
  }
`;

const Content = styled.picture`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  ${media.tabletLarge`
    flex: 0 0 27.9%;
    padding-right: 20px;
  `}
`;

const MediaWrapper = styled.picture`
  margin-top: 30px;
  width: 100%;

  ${media.tabletLarge`
  flex: 0 0 72.1%;
    margin-top: 0;
  `}
`;
